<template>
<div class="">
     <NavBar
   title="订单列表"
   left-arrow
   @click-left="$router.go(-1)"
  />
  <Card v-for="(idx,index) in orderlist"  :key="index" 
    :price="formatMoney(idx.total)"
    :title="idx.name"
    num="1"
    :thumb="require('@/assets/healthpackages/a.jpg')"
  >
   <template #desc>
     <div class="d_desc">
        {{idx.package.des}}
    </div>
  </template>
   <template #tag>
    <tag round :type="status(idx)" v-if="idx.state==-1"  >已取消</tag>
    <tag round :type="status(idx)" v-if="idx.state==1"  >已支付</tag>
    <tag round :type="status(idx)" v-if="idx.state==0"  >未支付</tag>
  </template>
  <template #tags>
    <tag plain type="primary"  >{{idx.create_date}}</tag>
  </template>
  </Card>
  <empty v-if="nodata" description="您还没有订购记录" />
</div>
</template>

<script>
import { Empty,Card ,Tag  } from 'vant';
import {listOrders} from '@/api/healthpackages';
import {getUser} from "@/api/user";

export default {
  name: 'orderlist',
  components: {
     Empty,Card ,Tag 
  },
  data(){
    return {
      user:{
        userName:'',
        nickName:'',
        idCard:'',
        phone:'',
        gender:'1',
        birthday:'',
        id:'',
        pid:''
      },
        orderlist:[],
        nodata:false,
        packageMap:this.$store.state.hmpackages.packageMap
    };
  },
  methods:{
     status(idx){
       if(idx.state==0){
         return "warning";
       }
       if(idx.state==1){
         return "primary";
       }else{
         return "danger";
       }
     },
     showOrder(idx){
       this.$router.push({path: '/healthmanage/orderdetail/'+idx.orderNumber});
     }
  },
   created() {
     let query = this.$route.query;
     let patientid=query.patientid;
     // console.log(patientid)
     getUser({patientid:patientid}).then((res) => {
       console.info(res.data);

       if(res.data){
         this.$store.commit('updateOpenid',res.data.openid);
         this.$store.commit('updatePid',res.data.id);
         this.$store.commit('updateBaseInfo',res.data);
         // if(res.data.name !=''){
         //   this.substatus = true
         // }
         this.user.id=res.data.id;
         this.user.name=res.data.name;
         this.user.birthday=res.data.birthday;
         // this.user.idCard=response.data.idCard;
         this.user.telephone=res.data.telephone;
         this.user.gender=res.data.gender+'';
         this.user.address=res.data.address;
         this.user.detail_address=res.data.detail_address;
         // this.initBaseInfo();
         // this.user_empty = false

         // this.initData();
         listOrders({patientid:patientid}).then((response) => {
           console.log(response)
           if(response.code==200 ){
             this.orderlist=response.data;
             if(this.orderlist.length==0){
               this.nodata=true;
             }
           }
         });

       }
     });

  },
}
</script>

<style scoped>
.is_completed{
  color: aqua;
}
.is_uncompleted{
  color:red;
}
.is_cancel{
  color:gray;
}
.d_desc{
   overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
</style>
